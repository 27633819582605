import React, { useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import PhizzleLogo from "../Images/phizzlelogo.png";
import StoreImg from "../Images/play-store (2) 1.png";
import Megaphone from "../Images/megaphone 1.png";
import Development from "../Images/software-development 1.png";
import simulatorImg from "../Images/video 1.png";
import DriverImg from "../Images/driver 1.png";
import PlatformImg from "../Images/platform 1.png";
import MonitorImg from "../Images/monitor 1.png";
import AlertImg from "../Images/alert.png";
import CommandLine from "../Images/command-line 1.png";
import "./index.css";
function NavDrawn() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleItemClick = () => {
    setShow(false);
  };
  const imageLogo = JSON.parse(localStorage.getItem("imgLogo"))
  return (
    <>
      <div className="nav-drawn">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            {/* <Button variant="primary">Launch</Button> */}
          </Container>
        </Navbar>
        <Offcanvas show={show} onHide={handleClose} id="logoDown">
          <Offcanvas.Header closeButton>
            {" "}
            <img
              className="brand"
              src={imageLogo}
            />
          </Offcanvas.Header>
          <Offcanvas.Body className="canvas">
            <nav className="drawn">
              <div>
                <Link to="/store" className="link-d" onClick={handleItemClick}>
                  <div className="eachStores">
                    <img className="storeIcon" src={StoreImg} />
                    <button className="accountBtn">
                      <h5 className="storeColors">Store</h5>
                    </button>
                  </div>
                </Link>
                <Link to="/news" className="link-d" onClick={handleItemClick}>
                  <div className="eachStores">
                    <img className="storeIcon" src={Megaphone} />

                    <button className="accountBtn">
                      <h5 className="storeColors">News</h5>
                    </button>
                  </div>
                </Link>
                <div className="eachStores">
                  <img className="storeIcon" src={Development} />
                  <button className="accountBtn">
                    <h5 className="storeColors">Developer Kit</h5>
                  </button>
                </div>
              </div>
              <div className="navbarMiddile">
                <h5 className="yourAccount">Your Account</h5>
                <Link
                  to="simulators"
                  className="link-d"
                  onClick={handleItemClick}
                >
                  <div className="eachStores">
                    <img className="storeIcon" src={simulatorImg} />
                    <button className="accountBtn">
                      <h5 className="storeColors">Simulators (2/101)</h5>
                    </button>
                  </div>
                </Link>
                <Link to="/driver" className="link-d" onClick={handleItemClick}>
                  <div className="eachStores">
                    <img className="storeIcon" src={DriverImg} />
                    <button className="accountBtn">
                      <h5 className="storeColors">Drivers (15/204)</h5>
                    </button>
                  </div>
                </Link>
                <Link
                  to="/platforms"
                  className="link-d"
                  onClick={handleItemClick}
                >
                  <div className="eachStores">
                    <img className="storeIcon" src={PlatformImg} />
                    <button className="accountBtn">
                      <h5 className="storeColors">Platforms (0/2)</h5>
                    </button>
                  </div>
                </Link>
                <div className="eachStores">
                  <img className="storeIcon" src={MonitorImg} />
                  <button className="accountBtn">
                    <h5 className="storeColors">Monitoring Solution (0/1)</h5>
                  </button>
                </div>
                <div className="eachStores">
                  <img className="storeIcon" src={AlertImg} />
                  <button className="accountBtn">
                    <h5 className="storeColors">Alert Engine (0/1)</h5>
                  </button>
                </div>
                <div className="eachStores">
                  <img className="storeIcon" src={CommandLine} />
                  <button className="accountBtn">
                    <h5 className="storeColors">Admin Console (0/1)</h5>
                  </button>
                </div>
              </div>
            </nav>
            {/* <div className="offcanvasDrawn">
              <div className="storeLogos">
                <p className="powered">Powered By</p>
                <div className="displayFlex">
                  <img className="logoIoT" src={PhizzleLogo} />
                  <h1 className="hub navbarMiddiles">Hub</h1>
                </div>
              </div>
              <p className="version">Version 1.0.0</p>
            </div> */}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default NavDrawn;
