import { Component } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import All from "../Images/app 1.png";
import Pause from "../Images/Polygon 4.png";
import "./index.css";
class Simulators extends Component {
  state = { condition: true, active: "" };
  changeActive1 = () => {
    this.setState({ active: "menton" });
  };
  changeActive2 = () => {
    this.setState({ active: "met3400" });
  };
  goToSimulator = () => {
    this.setState({ condition: true });
  };
  changeCondition = () => {
    this.setState({ condition: false });
  };
  simulatorChange = () => {
    return (
      <div className="sim-bg">
        <h4 className="all">My Simulator</h4>
        <div className="activeItems">
          <div className="displayFlex accountAc">
            <img className="all" src={All} />
            <h4 className="alls">All</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="alls">Pharma</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="alls">AutoMobile</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="alls">Fleet</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="alls">Agriculture</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="alls">Solor</h4>
          </div>
        </div>
        <hr className="whiteLine" />
        <div>
          <h6 className="andpharma">#Pharma</h6>
          <div className="simCont">
            <div>
              <h5 className="alls">Meton-3400 Particular Counter</h5>
              <h4 className="greyColor">Simulator</h4>
              <div className="runn">
                <p className="running">1 - Running</p>
                <p className="active">2 - Available</p>
              </div>
            </div>
            <div onClick={this.changeCondition} className="runns">
              <h5 className="views">View</h5>
              <img className="redPause" src={Pause} />
            </div>
          </div>
        </div>

        <h6 className="andpharma">#Pharma</h6>
        <div className="simCont">
          <div>
            <h5 className="alls">Seven Excellence / pH meter</h5>
            <h4 className="greyColor">Simulator</h4>
            <div className="runn">
              <p className="running">1 - Running</p>
              <p className="active">2 - Available</p>
            </div>
          </div>
          <div onClick={this.changeCondition} className="runns">
            <h5 className="views">View</h5>
            <img className="redPause" src={Pause} />
          </div>
        </div>
      </div>
    );
  };
  grabId = () => {
    return (
      <div className="toogleDiv">
        <div className="lastNav">
          <button className="interface">Interface</button>
          <button className="interface">API Spec</button>
          <button className="interface">Logs</button>
          <button className="interface">Usage</button>
        </div>
      </div>
    );
  };
  simulatorDetails = () => {
    const { active } = this.state;
    return (
      <div className="finn">
        <div onClick={this.goToSimulator} className="reactIcon">
          <IoArrowBackOutline className="react-icon" />
          <h5 className="all">Back to Sumulators</h5>
        </div>
        <div className="displayFin">
          <div className="flex-div">
            <buttton className="addNew">Add New</buttton>
            <div className="eachBagPink">
              <div>
                <p className={active === "menton" ? "metones" : "metone"}>
                  Metone Simulator 1
                </p>
                <p className="met3400">MetOne 3400 - Particle Counter</p>
              </div>
              <div onClick={this.changeActive1} className="circleDisplay1">
                <div
                  className={
                    active === "menton" ? "circle-div4" : "circle-div3"
                  }
                ></div>
                <p
                  className={
                    active === "menton" ? "activeTypes1" : "activeTypes"
                  }
                >
                  select
                </p>
              </div>
            </div>
            <div className="eachBagPink">
              <div>
                <p className={active === "met3400" ? "metones" : "metone"}>
                  Metone Simulator 1
                </p>
                <p className="met3400">MetOne 3400 - Particle Counter</p>
              </div>
              <div onClick={this.changeActive2} className="circleDisplay1">
                <div
                  className={
                    active === "met3400" ? "circle-div4" : "circle-div3"
                  }
                ></div>
                <p
                  className={
                    active === "met3400" ? "activeTypes1" : "activeTypes"
                  }
                >
                  select
                </p>
              </div>
            </div>
          </div>
          {active === "menton" || active === "met3400" ? this.grabId() : ""}
        </div>
      </div>
    );
  };

  render() {
    const { condition } = this.state;
    return <>{condition ? this.simulatorChange() : this.simulatorDetails()}</>;
  }
}

export default Simulators;
