import React from "react";
// import Home from "./components/Home";
// import Signup from "./components/Signup";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ShowcaseRoute from "./ShowcaseRoute";
import UserRoute from "./UserRoute";
import Example from "./components/NavDrawn";
import Notfound from "./components/Notfound";

// import Account from "./components/Account";
// import SimulatorItem from "./components/SimulaterItem";
// import Developerkit from "./components/Developerkit";

export default function App() {
  return (
    <BrowserRouter>
      <ShowcaseRoute />
      <UserRoute />
    </BrowserRouter>
  );
}
