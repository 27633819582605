import PhizzleLogo from "../Images/phizzlelogo.png";
import All from "../Images/app 1.png";
import "./index.css";
const Platform = () => (
  <div className="sim-bg">
    <h4 className="alls">My Platforms</h4>
    <div className="activeItems">
      <div className="displayFlex accountAc">
        <img className="all" src={All} />
        <h4 className="alls">All</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Pharma</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">AutoMobile</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Fleet</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Agriculture</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Solor</h4>
      </div>
    </div>
    <hr className="whiteLine" />
    <div className="bottom-div">
      <div className="each-div">
        <div className="image-div">
          {/* <img className="phizzle-img" src={PhizzleLogo} /> */}
        </div>
        <div className="plant-div">
          <h5 className="h5-margin">Digital Plant - CE</h5>
          <p className="community">Community Edition for all IoT Devices</p>
        </div>
        <button className="button-div">Try it for Free</button>
      </div>
      <div className="each-div">
        <div className="image-div">
          {/* <img className="phizzle-img" src={PhizzleLogo} /> */}
        </div>
        <div className="plant-div">
          <h5 className="h5-margin">Digital Plant - EE</h5>
          <p className="community">Community Edition for all IoT Devices</p>
        </div>
        <button className="button-div">Try it for Free</button>
      </div>
    </div>
  </div>
);

export default Platform;
