import React from "react";
import { useState } from "react";
import { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import "./index.css";
import PhizzleLogo from "../Images/phizzlelogo.png";
import NavDrawn from "../NavDrawn";
import StoreImg from "../Images/play-store (2) 1.png";
import Megaphone from "../Images/megaphone 1.png";
import Development from "../Images/software-development 1.png";
import simulatorImg from "../Images/video 1.png";
import DriverImg from "../Images/driver 1.png";
import PlatformImg from "../Images/platform 1.png";
import MonitorImg from "../Images/monitor 1.png";
import AlertImg from "../Images/alert.png";
import CommandLine from "../Images/command-line 1.png";
import Exit from "../Images/exit 1.png";
// class Layout extends Component 
function Layout() {
  // state = { active: "" };
  const [active, setActive] = useState('store')

  // handleNavigation = () => {
  //   const navigate = this.props.navigate;
  //   navigate("/account/developerkit");
  // };
  const changeActive = (event) => {
    // this.setState({ active: "store" });
    const name = event.target.getAttribute("name")
    setActive(name)
  };

  // render() {
  // const { active } = this.state;
  const imageLogo = JSON.parse(localStorage.getItem("imgLogo"))
  return (
    <>
      <div className="top-logout">
        <NavDrawn />
        <div className="logout2">
          <img className="logout-icon" src={Exit} />
          <h5 className="alls">Logout</h5>
        </div>
      </div>
      <div className="account-bg">
        <nav className="drawn drawn-kd">
          <div className="brandCont">
            <img
              className="brand"
              src={imageLogo}
            />
          </div>
          <div>
            <Link to="/store" className="link-d">
              <div
                name="store" onClick={changeActive}
                className={active === "store" ? "eachStore" : "eachStores"}
              >
                <img name="store" className="storeIcon" src={StoreImg} />
                <button className="accountBtn" name="store">
                  <h5
                    name="store"
                    className={
                      active === "store" ? "storeColor" : "storeColors"
                    }

                  >
                    Store
                  </h5>

                </button>
              </div>
            </Link>
            <Link to="/news" className="link-d">
              <div
                name="alert" onClick={changeActive}
                className={active === "alert" ? "eachStore" : "eachStores"}
              >
                <img name="alert" className="storeIcon" src={Megaphone} />
                <button className="accountBtn" name="alert">
                  <h5
                    name="alert"
                    className={
                      active === "alert" ? "storeColor" : "storeColors"
                    }
                  >
                    News
                  </h5>
                </button>
              </div>
            </Link>
            <div name="Kit" onClick={changeActive} className={active === "Kit" ? "eachStore" : "eachStores"}>
              <img name="Kit" className="storeIcon" src={Development} />
              <button className="accountBtn" name="Kit"  >
                <h5
                  name="Kit"
                  className={active === "Kit" ? "storeColor" : "storeColors"}

                >
                  Developer Kit
                </h5>
              </button>
            </div>
          </div>
          <div className="navbarMiddile">
            <h5 className="yourAccount">Your Account</h5>
            <Link to="simulators" className="link-d">
              <div
                name="simulator"
                onClick={changeActive}
                className={
                  active === "simulator" ? "eachStore" : "eachStores"
                }
              >
                <img name="simulator" className="storeIcon" src={simulatorImg} />
                <button className="accountBtn" name="simulator">
                  <h5
                    name="simulator"
                    className={
                      active === "simulator" ? "storeColor" : "storeColors"
                    }

                  >
                    Simulators (2/101)
                  </h5>
                </button>
              </div>
            </Link>
            <Link to="/driver" className="link-d">
              <div
                name="driver"
                onClick={changeActive}
                className={active === "driver" ? "eachStore" : "eachStores"}
              >
                <img name="driver" className="storeIcon" src={DriverImg} />
                <button className="accountBtn" name="driver"  >
                  <h5
                    name="driver"
                    className={
                      active === "driver" ? "storeColor" : "storeColors"
                    }

                  >
                    Drivers (15/204)
                  </h5>
                </button>
              </div>
            </Link>
            <Link to="/platforms" className="link-d">
              <div
                name="forms"
                onClick={changeActive}
                className={active === "forms" ? "eachStore" : "eachStores"}
              >
                <img name="forms" className="storeIcon" src={PlatformImg} />
                <button className="accountBtn" name="forms">
                  <h5
                    name="forms"
                    className={
                      active === "forms" ? "storeColor" : "storeColors"
                    }

                  >
                    Platforms (0/2)
                  </h5>
                </button>
              </div>
            </Link>
            <div
              onClick={changeActive}
              name="monitoring"
              className={active === "monitoring" ? "eachStore" : "eachStores"}
            >
              <img name="monitoring" className="storeIcon" src={MonitorImg} />
              <button className="accountBtn" name="monitoring" >
                <h5
                  name="monitoring"
                  className={
                    active === "monitoring" ? "storeColor" : "storeColors"
                  }

                >
                  Monitoring Solution (0/1)
                </h5>
              </button>
            </div>
            <div name="engine" onClick={changeActive} className={active === "engine" ? "eachStore" : "eachStores"}>
              <img name="engine" className="storeIcon" src={AlertImg} />
              <button className="accountBtn" name="engine" >
                <h5
                  name="engine"
                  className={
                    active === "engine" ? "storeColor" : "storeColors"
                  }
                >
                  Alert Engine (0/1)
                </h5>
              </button>
            </div>
            <div onClick={changeActive} name="admin" className={active === "admin" ? "eachStore" : "eachStores"}>
              <img name="admin" className="storeIcon" src={CommandLine} />
              <button className="accountBtn" name="admin">
                <h5
                  name="admin"
                  className={
                    active === "admin" ? "storeColor" : "storeColors"
                  }
                >
                  Admin Console (0/1)
                </h5>
              </button>
            </div>
          </div>

          {/* <div className="storeLogos">
              <p className="powered">Powered By</p>
              <div className="displayFlex">
                <img className="logos" src={PhizzleLogo} />
                <h1 className="hub navbarMiddilesr">Hub</h1>
              </div>
            </div>
            <p className="version">Version 1.0.0</p> */}
        </nav>
        <div className="account-bg-2">
          <div className="logout1">
            <img className="logout-icon" src={Exit} />
            <h5 className="alls">Logout</h5>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
  // }
}
export default Layout;
