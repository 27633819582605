import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";
import "./index.css";
const News = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <div className="news-bg">
      <div className="slick">
        <Slider {...settings}>
          <div className="eachDiv">
            <h6 className="latest">Latest News</h6>
            <div>
              <ReactPlayer
                className="videos"
                width="80%"
                height={200}
                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
              />
            </div>
            <div className="discriptions">
              <h5 className="googleH">
                Google Shutting Down IoT Core Services
              </h5>
              <p className="para11">
                Google Yet to shutdown Some of the C0re IoT services from google
                cloud
              </p>
            </div>
          </div>
          <div className="eachDiv" style={{ width: 40 }}>
            <h6 className="latest">IoT Store latest</h6>
            <div>
              <ReactPlayer
                className="videos"
                width="80%"
                height={200}
                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
              />
            </div>
            <div className="discriptions">
              <h5 className="googleH">Metone3400 - Simulator</h5>
              <p className="para11">
                Google yet to shutdown some of the Core IoT services from Google
                Cloud
              </p>
            </div>
          </div>
          <div className="eachDiv" style={{ width: 40 }}>
            <h6 className="latest">IoT</h6>
            <div>
              <ReactPlayer
                className="videos"
                width="80%"
                height={200}
                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
              />
            </div>
            <div className="discriptions">
              <h5 className="googleH">Metone3400 - Simulator</h5>
              <p className="para11">
                Google yet to shutdown some of the Core IoT services from Google
                Cloud
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="bottomDiv">
        <h6 className="latests">Top 20 Articals</h6>
        <div className="news-bottom-item">
          <div className="eachDiv">
            <div className="eachVideo">
              {/* <img className = "pause" src="https://res.cloudinary.com/doc438hex/image/upload/v1683699052/Polygon_2_b367wk.png"/> */}
            </div>
            <div className="discription">
              <h5 className="googleH">Automated sample collection</h5>
              <p className="para11">
                Google yet to shutdown some of the Core IoT services from Google
                Cloud
              </p>
            </div>
          </div>
          <div className="eachDiv">
            <div className="eachVideo">
              {/* <img className = "pause" src="https://res.cloudinary.com/doc438hex/image/upload/v1683699052/Polygon_2_b367wk.png"/> */}
            </div>
            <div className="discription">
              <h5 className="googleH">
                How Particle Counter helping corporate
              </h5>
              <p className="para11">
                Google yet to shutdown some of the Core IoT services from Google
                Cloud
              </p>
            </div>
          </div>
          <div className="eachDiv">
            <div className="eachVideo">
              <img
                className="pause"
                src="https://res.cloudinary.com/doc438hex/image/upload/v1683699052/Polygon_2_b367wk.png"
              />
            </div>
            <div className="discription">
              <h5 className="googleH">
                Get your university air sanity checked
              </h5>
              <p className="para11">
                Google yet to shutdown some of the Core IoT services from Google
                Cloud
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default News;
