import { Component } from "react";
// import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import { Link as ScrollLink, Element } from "react-scroll";
import PhizzleLogo from "../Images/phizzlelogo.png";
import Mobile from "../Images/mobile.png";
import Driver from "../Images/driver.png";
import Platform from "../Images/platform.png";
import Monitor from "../Images/monitor.png";
import Alert from "../Images/alert.png";
import CommandLine from "../Images/command-line.png";
import Contributer from "../Images/buyer 1.png";
import Partner from "../Images/partner 1.png";
import BuildBotLogo from "../Images/Group 13 (15).png";
import "./index.css";

class Home extends Component {
  state = { active: "", show: false };
  changeActiveSt = () => {
    this.setState({ active: "St" });
  };
  changeActiveCo = () => {
    this.setState({ active: "Co" });
  };
  changeActiveOu = () => {
    this.setState({ active: "Ou" });
  };
  changeActiveBl = () => {
    this.setState({ active: "Bl" });
  };
  // changeActiveSi = () =>{
  //     this.setState({active : "Si"})
  // }
  // constructor() {
  //   return <Spinner animation="border" />;
  // }
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  handleItemClick = () => {
    this.setState({ show: false });
  };
  render() {
    const { active, show } = this.state;
    return (
      <>
        <div className="main-bg">
          <div className="header-nav">
            <div className="logo-cont">
              {/* <img
                className="logo-main"
                src={PhizzleLogo}
                /> */}
              <h1 className="iotLogo">IoT</h1>
              <h1 className="hub">Hub</h1>
            </div>
            <div className="navbar-sm">
              <Navbar bg="white" expand="lg">
                <Container>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={this.handleShow}
                  />
                </Container>
              </Navbar>
              <Offcanvas
                show={show}
                onClick={this.handleClose}
                placement="end"
                id="show-bg"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body id="offcanvas-bg">
                  <ScrollLink to="store" smooth={true} duration={500}>
                    <h3 className="nav-home" onClick={this.handleItemClick}>
                      Store
                    </h3>
                  </ScrollLink>
                  <br />
                  <ScrollLink to="community" smooth={true} duration={500}>
                    <h3 className="nav-home" onClick={this.handleItemClick}>
                      Community
                    </h3>
                  </ScrollLink>
                  <br />
                  <ScrollLink to="products" smooth={true} duration={100}>
                    <h3 className="nav-home" onClick={this.handleItemClick}>
                      Products
                    </h3>
                  </ScrollLink>
                  <br />
                  <ScrollLink to="blog" smooth={true} duration={400}>
                    <h3 className="nav-home" onClick={this.handleItemClick}>
                      Blog
                    </h3>
                  </ScrollLink>
                  <br />
                  {/* <Nav.Link> */}
                  <div className="button-sign nav-home">
                    <Link to="/signup">
                      <button className="highlights">
                        <h3 className="top-nav-names">Sign Up</h3>
                      </button>
                    </Link>
                  </div>
                  {/* </Nav.Link> */}
                </Offcanvas.Body>
              </Offcanvas>
            </div>
            <div className="nav-names">
              <ScrollLink to="store" smooth={true} duration={0}>
                <h3
                  className={active === "St" ? "highlight" : "navBtn"}
                  onClick={this.changeActiveSt}
                >
                  Store
                </h3>
              </ScrollLink>

              <ScrollLink to="community" smooth={true} duration={0}>
                <h3
                  className={active === "Co" ? "highlight" : "navBtn"}
                  onClick={this.changeActiveCo}
                >
                  Community
                </h3>
              </ScrollLink>

              <ScrollLink to="products" smooth={true} duration={0}>
                <h3
                  className={active === "Ou" ? "highlight" : "navBtn"}
                  onClick={this.changeActiveOu}
                >
                  Our Products
                </h3>
              </ScrollLink>

              <ScrollLink to="blog" smooth={true} duration={0}>
                <h3
                  className={active === "Bl" ? "highlight" : "navBtn"}
                  onClick={this.changeActiveBl}
                >
                  Blog
                </h3>
              </ScrollLink>

              <Link to="/signup">
                <button className="highlights">
                  <h3 className="top-nav-names">Sign Up</h3>
                </button>
              </Link>
            </div>
          </div>
          <div className="storeMi">
            <div>
              <h1 className="main-heading">
                An IoT <span className="eco">EcoSystem</span>. IoT{" "}
                <span className="eco">Hub</span>. IoT{" "}
                <span className="eco">Store</span>.
              </h1>
            </div>
            <div>
              <p>
                Simply an IoT ecoSystem where you can find device{" "}
                <span>simulaters </span>agents/<span>drivers</span>,IoT
                <span>
                  platforms alert engines,monitoring solutions,admin console for
                  your IoT devices
                </span>
              </p>
            </div>
            <button className="browse">Browse Store</button>
          </div>
          <div className="storeLogo">
            <p className="powered">Powered By</p>
            <img className="logo buildbotLogo" src={BuildBotLogo} />
          </div>
        </div>
        <Element className="storePink" id="store">
          <h3 className="community-name">Store</h3>
          <div className="itemsContainer">
            <div className="eachItem">
              <img className="itemImage" src={Mobile} />
              <p className="paraDevice">Device Simulators</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>
              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
            <div className="eachItem">
              <img className="itemImage" src={Driver} />
              <p className="paraDevice">Device Agent/Driver</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>

              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
            <div className="eachItem">
              <img className="itemImage" src={Platform} />
              <p className="paraDevice">IoT Platform</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>
              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
            <div className="eachItem">
              <img className="itemImage" src={Monitor} />
              <p className="paraDevice">Monitoring Solution</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>
              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
            <div className="eachItem">
              <img className="itemImage" src={Alert} />
              <p className="paraDevice">Alert Engine Solution</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>
              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
            <div className="eachItem">
              <img className="itemImage" src={CommandLine} />
              <p className="paraDevice">Admin Console</p>
              <p className="paraItem">
                Simulator is a Virtual Device which exposes the same interface
                just like your real device. You don’t need to buy a real device
                to implement driver or to test with your IoT Platform.
              </p>
              <div>
                <button className="expore">Explore</button>
              </div>
            </div>
          </div>
        </Element>

        <Element className="community" id="community">
          <h2 className="community-name">Community</h2>
          <div className="horizontal1">
            <hr />
          </div>
          <div className="paraContainer">
            <p className="storeParaLI">
              One can’t a build a common interface for everything in IoT. And
              even though it does, it can’t be a proprietary to give the freedom
              of open-source. We’re enabling anyone to come and understand the
              ecosystem, SDK’s for developing simulators, drivers or agents,
              platforms, monitoring solutions, alert engines and admin consoles
              for any industry w.r.t to IoT.
            </p>
          </div>
        </Element>
        <div className="storeMiddle">
          <div className="storeIcons">
            <div className="icon">
              <img className="storeImg storeImgs" src={Contributer} />
              <h4 className="cont">Contributer</h4>
              <h4 className="cont">(Bounty Reward)</h4>
            </div>
            <p className="storeParaLI">
              You’re an engineer or developer who found this interesting and you
              think your knowledge and technical expertise can help in
              implementing different parts of the ecosystem i.e., device
              simulator, driver, etc then your work will be awarded a bounty($)
              depends on the specific work item.
            </p>
            <div className="buttonAlign">
              <button className="expores">Explore</button>
            </div>
          </div>
          <div
            style={{ borderLeft: "1px solid black", height: "400px" }}
            className="line"
          ></div>

          <div className="storeIcons">
            <div className="icon">
              <img className="storeImg" src={Partner} />
              <h4 className="cont">Implementation</h4>
              <h4 className="cont">Partner</h4>
            </div>
            <p className="storeParaLI">
              You’re a start-up or a software consulting or development company
              and interested in developing an IoT platform for a specific
              use-case? We’re offering an implementation partnership w.r.t the
              specific use-case and get benifited with our network.
            </p>
            <div className="buttonAlign">
              <button className="expores">Explore</button>
            </div>
          </div>
        </div>
        <Element className="bottomCont" id="products">
          <div class="wrapper">
            <svg
              class="svg1"
              width="231"
              height="180"
              viewBox="0 0 231 228"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M143.017 7.47964C155.696 -4.85501 176.904 0.805213 181.385 17.7196L229.359 198.83C233.883 215.906 217.995 231.614 200.835 227.034L17.0986 177.997C-0.0621102 173.417 -5.54424 152.005 7.25574 139.553L143.017 7.47964Z"
                fill="#FF9D8C"
                fillOpacity="0.59"
              />
            </svg>

            <svg
              class="svg2"
              width="234"
              height="180"
              viewBox="0 0 234 216"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M230 172.868C239.254 187.944 229.08 207.394 211.595 208.056L24.3724 215.146C6.7205 215.815 -5.12193 196.869 3.10995 181.131L91.2466 12.6211C99.4785 -3.11756 121.572 -3.77059 130.914 11.4486L230 172.868Z"
                fill="#FE6D88"
                fillOpacity="0.76"
              />
              <text
                class="text-svg"
                x="78%"
                y="80%"
                dominant-baseline="middle"
                text-anchor="middle"
                font-size="20"
                fill="black"
                fontWeight={600}
              >
                Our Products
              </text>
            </svg>
          </div>
          <p className="bottomPara">
            We Phizzle is a Product development company and we’re exclusively
            into IoT solutions. To know more about us please click here. We’ve a
            product called <span className="digital">Digital Plant</span> is an
            IoT solution for your manufacturing devices. Right now this is
            widely adopted by one of the monopoly in the Pharma Industry.
          </p>
          <p className="bottomPara">
            <span className="EDG">EDGMaker</span> - Unlocking & Digitizing the
            Most Valuable Scientific Data in Regulated Environments.
          </p>

          <p className="bottomPara">
            Phizzle is the only IoT solution to remotely operate multi-vendor,
          </p>
          <p className="bottomPara">
            multi-device scientific lab instruments​. ​The
            <span className="EDG">EDGMaker</span> software stack offers fully
            digitized testing and compliance processes to reduce labor, human
            error, and wasted batches in regulated manufacturing.
          </p>
          <div className="bottomButton">
            <button className="bottombtns exploreButton">Explore</button>
          </div>
        </Element>
        <Element id="blog">
          <h3 className="blog">Blog</h3>
          <div className="lastBottom">
            <div className="lastItem"></div>
            <div className="lastItem"></div>
            <div className="lastItem"></div>
          </div>
        </Element>
        <div className="footer">
          <h4 className="footer-name">All Rights Reserved by Buildbot @ 2022</h4>
        </div>
      </>
    );
  }
}

export default Home;
