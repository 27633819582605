import All from "../Images/app 1.png";
import Pause from "../Images/Polygon 4.png";
import "./index.css";
const Driver = () => (
  <div className="sim-bg">
    <h4 className="alls">My Drivers</h4>
    <div className="activeItems">
      <div className="displayFlex accountAc">
        <img className="all" src={All} />
        <h4 className="alls">All</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Pharma</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">AutoMobile</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Fleet</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Agriculture</h4>
      </div>
      <div className="displayFlex accountAcs">
        <h4 className="alls">Solor</h4>
      </div>
    </div>
    <hr className="whiteLine" />
    <div>
      <h6 className="andpharma">#Pharma</h6>
      <div className="simCont">
        <div>
          <h5 className="alls">Meton-3400 Particular Counter</h5>
          <h4 className="greyColor">Driver</h4>
          <div className="runn">
            <p className="running">1 - Running</p>
            <p className="active">2 - Available</p>
          </div>
        </div>
        <div className="runns">
          <h5 className="views">View</h5>
          <img className="redPause" src={Pause} />
        </div>
      </div>
    </div>
    <div className="con">
      <h6 className="andpharma">#Pharma</h6>
      <div className="simCont">
        <div>
          <h5 className="alls">Seven Excellence / pH meter</h5>
          <h4 className="greyColor">Driver</h4>
          <div className="runn">
            <p className="running">1 - Running</p>
            <p className="active">2 - Available</p>
          </div>
        </div>
        <div className="runns">
          <h5 className="views">View</h5>
          <img className="redPause" src={Pause} />
        </div>
      </div>
    </div>
  </div>
);

export default Driver;
