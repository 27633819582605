import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";

import Simulators from "./components/Simulators";
import Store from "./components/Store";
import News from "./components/News";
import Driver from "./components/Driver";
import Platform from "./components/Platforms";
import NavDrawn from "./components/NavDrawn";
import Notfound from "./components/Notfound";

export default class ShowcaseRoute extends React.Component {
  render() {
    return (
      <>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<NavDrawn />} />
            <Route path="/store" element={<Store />} />
            <Route path="/news" element={<News />} />
            <Route path="/simulators" element={<Simulators />} />
            <Route path="/driver" element={<Driver />} />
            <Route path="/platforms" element={<Platform />} />
            {/* <Route path="*" element={<Notfound />} /> */}
          </Route>
        </Routes>
      </>
    );
  }
}
