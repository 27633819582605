import React from "react";
import Home from "./components/Home";
import Signup from "./components/Signup";
import { Route, Routes } from "react-router-dom";
import Notfound from "./components/Notfound";

export default class ShowcaseRoute extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="*" element={<Notfound />} /> */}
      </Routes>
    );
  }
}
