import React from "react";
import ReactPlayer from "react-player";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Component } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./index.css";
import Particle from "../Images/Particle-Portable-Air-Counters-Met-One-3400-Full-View-2017-05 1.png";
import BlueImg from "../Images/image 1.png";
import All from "../Images/app 1.png";
import Check from "../Images/check 1.jpg";
class Store extends Component {
  state = { condition: true, active: "about" };

  changeState = () => {
    this.setState({ condition: false });
  };
  goToStore = () => {
    this.setState({ condition: true });
  };
  activeAbout = () => {
    this.setState({ active: "about" });
  };
  activeSim = () => {
    this.setState({ active: "sim" });
  };
  activeApi = () => {
    this.setState({ active: "api" });
  };
  activePrice = () => {
    this.setState({ active: "price" });
  };
  activePlat = () => {
    this.setState({ active: "plat" });
  };
  aboutPage = () => {
    return (
      <div>
        <div>
          {/* <Popup trigger={<img src ="https://www.youtube.com/watch?v=ysz5S6PUM-U"/>} position="right center"> */}
          <ReactPlayer
            className="video"
            width={800}
            height={300}
            url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          />
          {/* </Popup> */}
        </div>

        <div className="question">
          <h5 className="sim-head">What is this Simulator ?</h5>
          <p className="para1-2">
            Aerosol particle counters are used to determine the air quality by
            counting and sizing the number of particles in the air. This
            information is useful in determining the quantity of particles
            inside a building or in the ambient air. It also is useful in
            understanding the cleanliness level in a controlled environment.
          </p>
        </div>
        <div className="question">
          <h5 className="sim-head">How this works?</h5>
          <p className="para1-2">
            Aerosol particle counters are used to determine the air quality by
            counting and sizing the number of particles in the air. This
            information is useful in determining the quantity of particles
            inside a building or in the ambient air. It also is useful in
            understanding the cleanliness level in a controlled environment.
          </p>
        </div>
        <div className="question">
          <h5 className="sim-head">How is this useful for your application?</h5>
          <p className="para1-2">
            Aerosol particle counters are used to determine the air quality by
            counting and sizing the number of particles in the air. This
            information is useful in determining the quantity of particles
            inside a building or in the ambient air. It also is useful in
            understanding the cleanliness level in a controlled environment.
          </p>
        </div>
        <div className="question">
          <h5 className="sim-head">Protocols Supported</h5>
          <p className="para1-2">
            Aerosol particle counters are used to determine the air quality by
            counting and sizing the number of particles in the air. This
            information is useful in determining the quantity of particles
            inside a building or in the ambient air. It also is useful in
            understanding the cleanliness level in a controlled environment.
          </p>
        </div>
      </div>
    );
  };

  simInterface = () => {
    return (
      <div className="imageBlue-bg">
        <img className="blueImg" src={BlueImg} />
      </div>
    );
  };
  apiSdk = () => {
    return (
      <>
        <div className="top-api">
          <h5>REST API</h5>
          <div className="restApi-bg">
            <div className="small-rest">
              <h5 className="start">To start sample</h5>
              <br />
              <h5 className="post">POST</h5>
              <p className="jsonData">
                api = metone3400(“http://yourendpoint”, apikey=””)
                api.startSample(args);{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="api-bg-2">
          <h5>NODE JS</h5>
          <div className="restApi-bg">
            <h5 className="npm"> &gt;npm install iot-store-metone3400</h5>
            <div className="small-rest">
              <p className="jsonData">
                import metone3400 from iot-store-metone3400
              </p>

              <p className="jsonData">
                api = metone3400(“http://yourendpoint”, apikey=””)
                api.startSample(args);{" "}
              </p>
            </div>
          </div>
        </div>
        <div>
          <h5>PYTHON</h5>
          <div className="restApi-bg">
            <h5 className="npm"> &gt;pip install iot-store-metone3400</h5>
            <div className="small-rest">
              <p className="jsonData">from iotstoremetone import Metone3400</p>
              <p className="jsonData">
                api = Metone3400(“https://yourendpoint”, apiKey=””)
                api.start_sample(args);{" "}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  pricePage = () => {
    return (
      <div className="main-gold-bg">
        <h5 className="head-serv">As Service</h5>
        <div className="eachGold">
          <div className="eachPlatinum">
            <button className="basicBtn">BASIC</button>
            <div className="sm-content">
              <div className="circleDisplay">
                <div className="circle-div"></div>
                <p className="activeType">Active Types - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div"></div>
                <p className="activeType">No of SIM’s per type - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div"></div>
                <p className="activeType">API calls per day - 2000</p>
              </div>
            </div>
            <button className="basicBtn">Try it</button>
          </div>
          <div className="eachPlatinum2">
            <button className="basicBtn2">GOLD</button>
            <div className="sm-content">
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">Active Types - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">No of SIM’s per type - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">API calls per day - 2000</p>
              </div>
            </div>
            <button className="basicBtn2">Try it</button>
          </div>
          <div className="eachPlatinum3">
            <button className="basicBtn3">PLATINUM</button>
            <div className="sm-content">
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">Active Types - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">No of SIM’s per type - 2</p>
              </div>
              <div className="circleDisplay">
                <div className="circle-div2"></div>
                <p className="activeType">API calls per day - 2000</p>
              </div>
            </div>
            <button className="basicBtn3">Try it</button>
          </div>
        </div>
        <h5 className="head-serv">Offline Download</h5>
        <div className="footerLast">
          <h5>
            Please contact sales -{" "}
            <span className="sales">sales@iotstore.io</span>
          </h5>
        </div>
      </div>
    );
  };
  simulatorItem = () => {
    const { active } = this.state;
    return (
      <div className="store-page-1">
        <div onClick={this.goToStore} className="reactIcon">
          <IoArrowBackOutline className="react-icon" />
          <h5 className="all">Back to Store</h5>
        </div>
        <div className="store-page-rows">
          <div>
            <img className="sim" src={Particle} />
          </div>
          <div className="paragraph-bg">
            <h5 className="sim-head">Simulator</h5>
            <h5 className="met-head">Metone - Air Particle Counter</h5>
            <p className="para1-2">
              Aerosol particle counters are used to determine the air quality by
              counting and sizing the number of particles in the air. This
              information is useful in determining the quantity of particles
              inside a building or in the ambient air. It also is useful in
              understanding the cleanliness level in a controlled environment.
            </p>
          </div>
          <div className="button-add and">
            <button className="greenButton">ADD</button>
          </div>
        </div>
        <hr className="hori" />
        <div className="activeItems">
          <div
            onClick={this.activeAbout}
            className={
              active === "about"
                ? "displayFlex everyItem accountAcs highlited"
                : "displayFlex everyItem accountAcs"
            }
          >
            <h4 className="alls">About</h4>
          </div>
          <div
            onClick={this.activeSim}
            className={
              active === "sim"
                ? "displayFlex everyItem accountAcs highlited"
                : "displayFlex everyItem accountAcs"
            }
          >
            <h4 className="alls">SIM Interface</h4>
          </div>
          <div
            onClick={this.activeApi}
            className={
              active === "api"
                ? "displayFlex everyItem accountAcs highlited"
                : "displayFlex everyItem accountAcs"
            }
          >
            <h4 className="alls">API & SDK’s</h4>
          </div>
          <div
            onClick={this.activePrice}
            className={
              active === "price"
                ? "displayFlex everyItem accountAcs highlited"
                : "displayFlex everyItem accountAcs"
            }
          >
            <h4 className="alls">Pricing</h4>
          </div>
          <div
            onClick={this.activePlat}
            className={
              active === "plat"
                ? "displayFlex everyItem accountAcs highlited"
                : "displayFlex everyItem accountAcs"
            }
          >
            <h4 className="alls">Compatible Platforms</h4>
          </div>
        </div>
        {active === "about" && this.aboutPage()}
        {active === "sim" && this.simInterface()}
        {active === "api" && this.apiSdk()}
        {active === "price" && this.pricePage()}
      </div>
    );
  };
  storeChange = () => {
    return (
      <div className="account-tabs-bg">
        <div className="activeItems">
          <div className="displayFlex accountAc">
            <img className="all" src={All} />
            <h4 className="all">All</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="all">Pharma</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="all">AutoMobile</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="all">Fleet</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="all">Agriculture</h4>
          </div>
          <div className="displayFlex accountAcs">
            <h4 className="all">Solor</h4>
          </div>
        </div>
        <hr className="whiteLine" />
        <div className="finalCont">
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Metone - Air Particle Counter</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem" onClick={this.changeState}>
              Explore
            </button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Lasair - Air Particle Counter</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Aerotrack - Air Particle Counter</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Climet - Air Particle Counter</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Balance 3400</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <Link to="/simulatorItem">
              <button className="exploreItem">Explore</button>
            </Link>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">pH 3400</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Osmo</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Pharma</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Driver</h5>
            <h5 className="headSim">Metone - Air Particle Counter</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
          <div className="eachSim">
            <h6>#Solor</h6>
            <div className="simulator-bg">
              <img className="sim" src={Particle} />
            </div>
            <h5 className="headSim">Simulator</h5>
            <h5 className="headSim">Solar Panel Angle Monitor</h5>
            <div className="displayFlex">
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">TCP</h5>
              </div>
              <div className="rightMark">
                <img className="right" src={Check} />
                <h5 className="all">HTTP</h5>
              </div>
            </div>
            <button className="exploreItem">Explore</button>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { condition } = this.state;
    return <>{condition ? this.storeChange() : this.simulatorItem()}</>;
  }
}
export default Store;
