import { Component } from "react";
import { Popover, Steps } from "antd";
import { Link } from "react-router-dom";
import { Uploader } from "uploader"; // Installed by "react-uploader".
import { UploadButton } from "react-uploader";
import React from "react";
import PhizzleLogo from "../Images/phizzlelogo.png";
import Mobile from "../Images/mobile.png";
import Driver from "../Images/driver.png";
import Platform from "../Images/platform.png";
import Monitor from "../Images/monitor.png";
import Alert from "../Images/alert.png";
import CommandLine from "../Images/command-line.png";
import MoneyBag from "../Images/money-bag 1.png";
import PartyPoppar from "../Images/party-popper 1.png";
import "./index.css";

const uploader = Uploader({
  apiKey: "free", // Get production API keys from Upload.io
});
const stepStyles = {
  backgroundColor: "red", // Set the dot color
  borderColor: "red", // Set the line color
};
// Configuration options: https://upload.io/uploader#customize
const options = { multi: true };
const description = "This is a description.";
class Signup extends Component {
  state = {
    basic: true,
    services: false,
    branding: false,
    subscription: false,
    finish: false,
    first: "",
    last: "",
    companyName: "",
    companyWeb: "",
    companyEmail: "",
    role: "",
    settingupdomain: false,
    settingupservices: false,
    addingbranding: false,
    finishs: false,
    output: false,
    count: 0,
    imgsrc: ''
  };

  person = {
    basic: true,
    services: false,
    branding: false,
    subscription: false,
    finish: false,
  };
  horizontal = {
    services: false,
    branding: false,
    subscription: false,
    finish: false,
  };

  showBasic = () => {
    if (this.person.basic === true) {
      this.setState({
        basic: true,
        services: false,
        branding: false,
        subscription: false,
        finish: false,
      });
    }
  };

  showServices = () => {
    if (this.person.services === true) {
      this.setState({
        basic: false,
        services: true,
        branding: false,
        subscription: false,
        finish: false,
      });
    }
  };
  showBranding = () => {
    if (this.person.branding === true) {
      this.setState({
        basic: false,
        services: false,
        branding: true,
        subscription: false,
        finish: false,
      });
    }
  };
  showSubsciption = () => {
    if (this.person.subscription === true) {
      this.setState({
        basic: false,
        services: false,
        branding: false,
        subscription: true,
        finish: false,
      });
    }
  };
  showFinish = () => {
    if (this.person.finish === true) {
      this.setState({
        basic: false,
        services: false,
        branding: false,
        subscription: false,
        finish: true,
      });
    }
  };

  changeBasic = () => {
    this.setState((pre) => ({
      basic: false,
      services: true,
      branding: false,
      subscription: false,
      finish: false,
      count: 1,
    }));
    this.person.services = true;
    this.horizontal.services = true;
  };

  changeServices = () => {
    this.setState((pre) => ({
      basic: false,
      services: false,
      branding: true,
      subscription: false,
      finish: false,
      count: 2,
    }));
    this.person.branding = true;
    this.horizontal.branding = true;
  };
  changeBranding = () => {
    this.setState((pre) => ({
      basic: false,
      services: false,
      branding: false,
      subscription: true,
      finish: false,
      count: 3,
    }));
    this.person.subscription = true;
    this.horizontal.subscription = true;
  };
  changeSubsription = () => {
    this.setState((pre) => ({
      basic: false,
      services: false,
      branding: false,
      subscription: false,
      finish: true,
      count: 4,
    }));
    this.person.finish = true;
    this.horizontal.finish = true;
  };
  changeFinish = () => {
    this.setState((pre) => ({
      basic: false,
      services: false,
      branding: false,
      subscription: false,
      finish: false,
      count: 5,
    }));
    this.person.subscription = true;
    this.horizontal.subscription = true;
  };

  userDetails = {
    first: "",
    last: "",
    companyName: "",
    companyWeb: "",
    companyEmail: "",
    role: "",
  };

  storeFirstName = (event) => {
    this.userDetails.first = event.target.value;
    this.setState({ first: event.target.value });
  };
  storeLastName = (event) => {
    this.userDetails.last = event.target.value;
    this.setState({ last: event.target.value });
  };
  storeCompanyName = (event) => {
    this.userDetails.companyName = event.target.value;
    this.setState({ companyname: event.target.value });
  };
  storeWebName = (event) => {
    this.userDetails.companyWeb = event.target.value;
    this.setState({ companyweb: event.target.value });
  };
  storeCompanyEmail = (event) => {
    this.userDetails.companyEmail = event.target.value;
    this.setState({ companyemail: event.target.value });
  };
  storeRole = (event) => {
    this.userDetails.role = event.target.value;
    this.setState({ role: event.target.value });
  };
  settingUpDomain = () => {
    this.setState((prev) => ({ settingupdomain: !prev.settingupdomain }));
  };
  settingUpServices = () => {
    this.setState((prev) => ({ settingupservices: !prev.settingupservices }));
  };
  addingBranding = () => {
    this.setState((prev) => ({ addingbranding: !prev.addingbranding }));
  };
  finishOf = () => {
    this.setState((prev) => ({ finishs: !prev.finishs }));
  };
  changeOutput = () => {
    this.setState({ output: true });
  };
  handleUpload = (file) => {
    // Handle the uploaded image file
    // In this example, we set the image URL to display it

    console.log(file)
  };
  getSrcLinks = (files) => {
    return files.map((file) => file.fileUrl);
  }
  handleComplete = (files) => {
    const srcLinks = this.getSrcLinks(files);
    // Do something with the srcLinks array
    // For example, pass it to another function or store it in component state
    this.setState({ imgsrc: srcLinks })
    localStorage.setItem("imgLogo", JSON.stringify(srcLinks))
  }
  basicDetails = () => {
    const { first, last, companyname, companyweb, companyemail, role } =
      this.state;
    return (
      <>
        <div className="basicHide">
          <div className="flex">
            <h4 className={this.person.basic ? "circle" : "circles"}>1</h4>
            <h4
              className={this.person.basic ? "signup-head-pink" : "signup-head"}
              onClick={this.showBasic}
            >
              Basic Info
            </h4>
          </div>
        </div>
        <div className="signup-inputs">
          <div className="details">
            <label htmlFor="first">First Name</label>
            <input
              type="text"
              id="first"
              onChange={this.storeFirstName}
              value={first}
            />
          </div>
          <div className="details">
            <label htmlFor="last">Last Name</label>
            <input
              type="text"
              id="last"
              onChange={this.storeLastName}
              value={last}
            />
          </div>
          <div className="details">
            <label htmlFor="comname">Company Name</label>
            <input
              type="text"
              id="comname"
              onChange={this.storeCompanyName}
              value={companyname}
            />
          </div>
          <div className="details">
            <label htmlFor="comweb">Company Website</label>
            <input
              type="text"
              id="comweb"
              onChange={this.storeWebName}
              value={companyweb}
            />
          </div>
          <div className="details">
            <label htmlFor="commail">Company Email Address</label>
            <input
              type="text"
              id="comail"
              onChange={this.storeCompanyEmail}
              value={companyemail}
            />
          </div>
          <div className="details">
            <label htmlFor="comrole">Your Role/Designation</label>
            <input
              type="text"
              id="comrole"
              onChange={this.storeRole}
              value={role}
            />
          </div>
        </div>
        <div className="nextBtn">
          <button onClick={this.changeBasic} className="bottombtns">
            Next
          </button>
        </div>
      </>
    );
  };

  servicesDetails = () => {
    return (
      <>
        <div className="basicHide">
          <div className="flex">
            <h4 className={this.person.services ? "circle" : "circles"}>2</h4>
            <h4
              className={
                this.person.services ? "signup-head-pink" : "signup-head"
              }
              onClick={this.showServices}
            >
              Services
            </h4>
          </div>
        </div>
        <div className="service-bg">
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox1" className="check" />
              <label for="myCheckbox1" className="custom-checkbox"></label>
            </div>
            <img className="smallImage" src={Mobile} />
            <h4 className="name">Device Simulators</h4>
          </div>
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox2" className="check" />
              <label for="myCheckbox2" className="custom-checkbox"></label>
            </div>
            <img className="smallImage" src={Driver} />
            <h4 className="name">Device Drivers</h4>
          </div>
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox3" className="check" />
              <label for="myCheckbox3" className="custom-checkbox"></label>{" "}
            </div>
            <img className="smallImage" src={Platform} />
            <h4 className="name">IoT Platforms</h4>
          </div>
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox4" className="check" />
              <label for="myCheckbox4" className="custom-checkbox"></label>{" "}
            </div>
            <img className="smallImage" src={Monitor} />
            <h4 className="name">Monitoring</h4>
          </div>
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox5" className="check" />
              <label for="myCheckbox5" className="custom-checkbox"></label>{" "}
            </div>
            <img className="smallImage" src={Alert} />
            <h4 className="name">Alert Engine</h4>
          </div>
          <div className="serviceItem">
            <div className="kkk">
              <input type="checkbox" id="myCheckbox6" className="check" />
              <label for="myCheckbox6" className="custom-checkbox"></label>{" "}
            </div>
            <img className="smallImage" src={CommandLine} />
            <h4 className="name">Admin Console</h4>
          </div>
        </div>
        <div className="nextBtn">
          <button className="bottombtns hideBtn" onClick={this.showBasic}>
            Back
          </button>
          <button onClick={this.changeServices} className="bottombtns">
            Next
          </button>
        </div>
      </>
    );
  };

  brandingDetails = () => {
    const { imgsrc } = this.state
    return (
      <>
        <div className="basicHide">
          <div className="flex">
            <h4 className={this.person.services ? "circle" : "circles"}>3</h4>
            <h4
              className={
                this.person.services ? "signup-head-pink" : "signup-head"
              }
              onClick={this.showServices}
            >
              Branding
            </h4>
          </div>
        </div>
        <div className="branding-main">
          <div className="branding-bg">
            <div className="flex1">
              <div className="details">
                <label htmlFor="domain">Domain Name</label>
                <input
                  id="domain"
                  className="inputs"
                  type="text"
                  placeholder=".hub.phz.io"
                />
              </div>
              <h4>About Your Company</h4>
              <p className="para1">
                We Provide End-To-End Unified Cloud Platforms for Research &
                Development, Laboratory Information Management Systems,
                Workplace Health & Safety with embedded Quality and Predictive
                Compliance with Real-Time Monitoring On A Single Platform to
                Help You Manage Your Entire Process Seamlessly.
              </p>
            </div>
            <div
              className="line"
              style={{
                borderLeft: "1px solid black",
                height: "100px",
                marginTop: "50px",
              }}
            ></div>
            <div className="flex2">
              <h4 className="logoName">Logo</h4>
              <div className="logo-container">
                <img
                  className="brand"
                  // src="https://res.cloudinary.com/doc438hex/image/upload/v1683537597/Logo1_1_h6z1p0.png"
                  src={imgsrc}
                />
              </div>
              <UploadButton
                onChange={this.handleUpload}
                uploader={uploader}
                options={options}
                onComplete={this.handleComplete}
              >
                {({ onClick }) => (
                  <button className="uploadBtn" onClick={onClick}>
                    Upload
                  </button>
                )}
              </UploadButton>

              {/* <button className='uploadBtn'></button> */}
            </div>
          </div>
          <div className="brandingNxt">
            <button className="bottombtns hideBtn" onClick={this.showServices}>
              Back
            </button>
            <button onClick={this.changeBranding} className="bottombtns">
              Next
            </button>
          </div>
        </div>
      </>
    );
  };
  subscriptionDetails = () => {
    return (
      <>
        <div className="basicHide">
          <div className="flex">
            <h4 className={this.person.subscription ? "circle" : "circles"}>
              4
            </h4>
            <h4
              className={
                this.person.subscription ? "signup-head-pink" : "signup-head"
              }
              onClick={this.showSubsciption}
            >
              Subscription
            </h4>
          </div>
        </div>
        <div className="subscription-bg">
          <div className="money-bg">
            <img className="money" src={MoneyBag} />
          </div>
          <div className="para-bg">
            <p>
              There will be In-App purchases which differs from service to
              service and capacity to capacity. Please Add the service according
              to your need and capacity.
            </p>

            <p>
              There also few of the free and trail versions available if you
              want to do a test drive.
            </p>
          </div>
        </div>
        <div className="subBtn">
          <button className="bottombtns hideBtn" onClick={this.showBranding}>
            Back
          </button>
          <button onClick={this.changeSubsription} className="bottombtns">
            Next
          </button>
        </div>
      </>
    );
  };
  finishDetails = () => {
    const { settingupdomain, settingupservices, addingbranding, finishs } =
      this.state;
    return (
      <>
        <div className="basicHide">
          <div className="flex">
            <h4 className={this.person.subscription ? "circle" : "circles"}>
              5
            </h4>
            <h4
              className={
                this.person.subscription ? "signup-head-pink" : "signup-head"
              }
              onClick={this.showSubsciption}
            >
              Finish
            </h4>
          </div>
        </div>
        <div className="display-fit">
          <div className="check-tick" onClick={this.settingUpDomain}>
            <svg
              className={"my-path"}
              width="30"
              height="30"
              viewBox="0 0 36 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.51899 29L1 13.3284H11.8608L22.0949 1H34L22.0949 13.3284L8.51899 29Z"
                stroke="#333333"
                strokeOpacity="0.51"
              />
            </svg>
            <h5 className={"pink-col"}>Setting Up Domain</h5>
          </div>
          <div className="check-tick" onClick={this.settingUpServices}>
            <svg
              className={"my-path"}
              width="30"
              height="30"
              viewBox="0 0 36 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.51899 29L1 13.3284H11.8608L22.0949 1H34L22.0949 13.3284L8.51899 29Z"
                stroke="#333333"
                strokeOpacity="0.51"
              />
            </svg>
            <h5 className={"pink-col"}>Setting Up Services</h5>
          </div>
          <div className="check-tick" onClick={this.addingBranding}>
            <svg
              className={"my-path"}
              width="30"
              height="30"
              viewBox="0 0 36 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.51899 29L1 13.3284H11.8608L22.0949 1H34L22.0949 13.3284L8.51899 29Z"
                stroke="#333333"
                strokeOpacity="0.51"
              />
            </svg>
            <h5 className={"pink-col"}>Adding Branding</h5>
          </div>
          <div className="check-tick" onClick={this.finishOf}>
            <svg
              className={"my-path"}
              width="30"
              height="30"
              viewBox="0 0 36 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.51899 29L1 13.3284H11.8608L22.0949 1H34L22.0949 13.3284L8.51899 29Z"
                stroke="#333333"
                strokeOpacity="0.51"
              />
            </svg>
            <h5 className={"pink-col"}>Finish</h5>
          </div>
          <div className="subBtn">
            <button
              className="bottombtns hideBtn"
              onClick={this.showSubsciption}
            >
              Back
            </button>
            <button onClick={this.changeOutput} className="bottombtns">
              Done
            </button>
          </div>
        </div>
      </>
    );
  };

  stepsDetails = () => {
    const { basic, services, branding, subscription, finish, count } =
      this.state;
    return (
      <>
        <div className="displayFlex">
          <Steps
            className="steps"
            current={count}
            direction="vertical"
            items={[
              {
                title: "",
                onClick: () => this.showBasic(),
              },
              {
                title: "",
                onClick: () => this.showServices(),
              },
              {
                title: "",
                onClick: () => this.showBranding(),
              },
              {
                title: "",
                onClick: () => this.showSubsciption(),
              },
              {
                title: "",
                onClick: () => this.showFinish(),
              },
            ]}
          />

          <div className="buttonCrt">
            <div className="flexs">
              <div className="flex">
                <h4 className={this.person.basic ? "circle" : "circles"}>1</h4>
                <h4
                  className={
                    this.person.basic ? "signup-head-pink" : "signup-head"
                  }
                  onClick={this.showBasic}
                >
                  Basic Info
                </h4>
              </div>
              <hr
                className={
                  this.horizontal.services ? "horizontal" : "horizontals"
                }
              />
              <div className="flex">
                <h4 className={this.person.services ? "circle" : "circles"}>
                  2
                </h4>
                <h4
                  className={
                    this.person.services ? "signup-head-pink" : "signup-head"
                  }
                  onClick={this.showServices}
                >
                  Services
                </h4>
              </div>
              <hr
                className={
                  this.horizontal.branding ? "horizontal" : "horizontals"
                }
              />
              <div className="flex">
                <h4 className={this.person.branding ? "circle" : "circles"}>
                  3
                </h4>
                <h4
                  className={
                    this.person.branding ? "signup-head-pink" : "signup-head"
                  }
                  onClick={this.showBranding}
                >
                  Branding
                </h4>
              </div>
              <hr
                className={
                  this.horizontal.subscription ? "horizontal" : "horizontals"
                }
              />
              <div className="flex">
                <h4 className={this.person.subscription ? "circle" : "circles"}>
                  4
                </h4>
                <h4
                  className={
                    this.person.subscription
                      ? "signup-head-pink"
                      : "signup-head"
                  }
                  onClick={this.showSubsciption}
                >
                  Subscription
                </h4>
              </div>
              <hr
                className={
                  this.horizontal.finish ? "horizontal" : "horizontals"
                }
              />
              <div className="flex">
                <h4 className={this.person.finish ? "circle" : "circles"}>5</h4>
                <h4
                  className={
                    this.person.finish ? "signup-head-pink" : "signup-head"
                  }
                  onClick={this.showFinish}
                >
                  Finish
                </h4>
              </div>
            </div>

            {basic && this.basicDetails()}
            {services && this.servicesDetails()}
            {branding && this.brandingDetails()}
            {subscription && this.subscriptionDetails()}
            {finish && this.finishDetails()}
          </div>
        </div>
      </>
    );
  };
  congratulationsView = () => {
    return (
      <div className="finish-bg">
        <h2>Congratulations</h2>
        <img className="imageFinish" src={PartyPoppar} />
        <p>Your Account has been setup Successfully</p>
        <a href="https://buildbot.tech/" target="_blank">
          https://xybion.hub.phz.io
        </a>
        <div className="subBtns">
          <Link to="/store">
            <button className="bottombtns congraBtn">
              Take me to my account
            </button>
          </Link>
        </div>
      </div>
    );
  };

  renderFunction = () => {
    const { output } = this.state;
    return (
      <div className="sign-main-bg">
        <div className="sign-top">
          <div className="logo-cont signLogo">
            {/* <img src={PhizzleLogo} /> */}
            <h1 className="iotLogo">IoT</h1>
            <h1 className="hub">Hub</h1>
          </div>
        </div>
        <div className="sign-details">
          <div>{output ? this.congratulationsView() : this.stepsDetails()}</div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderFunction();
  }
}

export default Signup;
